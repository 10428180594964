<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div v-if="step === 1">
          <div class="has-text-right">
            <a class="has-text-danger" @click="$modal.close">Close</a>
          </div>
          <section class="content">
            <div class="has-text-centered">
              <img
                :src="require('core/img/icons/upsell-autocheck.svg')"
                width="450"
              />
            </div>
            <p class="title is-4 has-text-primary">
              Check this vehicle’s history to ensure it does not have finance,
              previous damage and much more
            </p>
            <p>
              Our comprehensive check provides you with all the essential
              information you need to make an informed decision:
            </p>
            <p>
              <strong class="title-highlight"
                >Insurance Write Off (Total loss) Check:</strong
              >
              Has the vehicle been declared an insurance total loss (category A,
              B, C, D, S & N), or written-off, including the loss type, loss
              date and damaged areas.
            </p>
            <p>
              <strong class="title-highlight"
                >Outstanding Finance Check:
              </strong>
              Has the vehicle got a finance agreement.
            </p>
            <p>
              <strong class="title-highlight">Imported/Exported: </strong> Has
              the vehicle been recorded as imported or exported by DVLA.
            </p>
            <p>
              <strong class="title-highlight">Scrapped/Unscrapped: </strong> Has
              the vehicle ever been scrapped or not.
            </p>
            <p>
              <strong class="title-highlight">Colour Change: </strong> Has the
              vehicle had a history of car colour change. Our check includes a
              record of the original colour and any colour changes.
            </p>
          </section>
          <div class="buttons is-right">
            <button class="button is-rounded is-soft" @click="step = 2">
              Get started
            </button>
          </div>
        </div>
        <section v-else>
          <BusinessEnquiry enquiry-type="autocheck" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpsellAutocheck',
  data: () => ({ step: 1 }),
  components: {
    BusinessEnquiry: () =>
      import('modules/contact/components/cazana/BusinessEnquiry')
  }
}
</script>

<style lang="sass" scoped>
.title-highlight
  color: $secondary-background
</style>
